export const clarity = [
    {
        practices: [{
            questions: [
                {
                    score: 5,
                    question: 'هل تمتلك الجمعية موقعاً إلكترونياً خاصاً بها؟',
                    evaluation: 'مكتبي',
                    check: 'وجود الموقع الإلكتروني الرسمي للجمعية',
                    availableAnswers: [
                        { answer: "يوجد موقع إلكتروني رسمي للجمعية", score: 1 },
                        { score: 0, answer: "لا يوجد موقع إلكتروني رسمي للجمعية" }
                    ]
                }
            ],
            score: 5
        },
        {
            questions: [
                {
                    score: 6,
                    question: 'هل نشرت الجمعية لائحتها الأساسية المعتمدة في موقعها الإلكتروني مع عدم عرض أرقام  هويات أعضاء الجمعية العمومية ؟',
                    evaluation: 'مكتبي',
                    check: 'وجود اللائحة الأساسية للجمعية',
                    availableAnswers: [
                        { answer: "تم نشر اللائحة", score: 1 },
                        { score: 0, answer: "لم يتم نشر اللائحة" }
                    ]
                }
            ],
            score: 6
        },
        {
            questions: [
                {
                    score: 2,
                    question: 'هل تم نشر سياسة جمع التبرعات على موقع الجمعية الإلكتروني؟',
                    evaluation: 'مكتبي',
                    check: 'محتوى سياسة جمع التبرعات المنشورة على موقع الجمعية الإلكتروني',
                    availableAnswers: [
                        { answer: "تم نشر السياسة بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر السياسة" }
                    ]
                },
                {
                    score: 2,
                    question: 'هل تم نشر سياسة تنظيم العلاقة مع المستفيدين وتقديم الخدمات على موقع الجمعية الإلكتروني؟',
                    evaluation: 'مكتبي',
                    check: 'محتوى سياسة تنظيم العلاقة مع المستفيدين وتقديم الخدمات المنشورة على موقع الجمعية الإلكتروني',
                    availableAnswers: [
                        { answer: "تم نشر السياسة بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر السياسة" }
                    ]
                },
                {
                    score: 2,
                    question: 'هل تم نشر آلية إدارة المتطوعين على موقع الجمعية الإلكتروني؟ ',
                    evaluation: 'مكتبي',
                    check: 'محتوى آلية إدارة المتطوعين المنشورة',
                    availableAnswers: [
                        { answer: "تم نشر آلية بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر آلية" }
                    ]
                },
                {
                    score: 2,
                    question: 'هل تم نشر سياسة تعارض المصالح المعتمدة في الجمعية على موقع الجمعية الإلكتروني؟',
                    evaluation: 'مكتبي',
                    check: 'محتوى سياسة تعارض المصالح المنشورة على موقع الجمعية الإلكتروني',
                    availableAnswers: [
                        { answer: "تم نشر السياسة بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر السياسة" }
                    ]
                },
                {
                    score: 2,
                    question: 'هل تم نشر سياسة الإبلاغ عن المخالفات وحماية مقدمي البلاغات المعتمدة على موقع الجمعية الإلكتروني؟',
                    evaluation: 'مكتبي',
                    check: 'محتوى سياسة الإبلاغ عن المخالفات وحماية مقدمي البلاغات المنشورة على موقع الجمعية الإلكتروني',
                    availableAnswers: [
                        { answer: "تم نشر السياسة بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر السياسة" }
                    ]
                },
                {
                    score: 2,
                    question: 'هل تم نشر سياسة خصوصية البيانات على موقع الجمعية الإلكتروني؟',
                    evaluation: 'مكتبي',
                    check: 'محتوى سياسة خصوصية البيانات المنشورة على موقع الجمعية الإلكتروني',
                    availableAnswers: [
                        { answer: "تم نشر السياسة بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر السياسة" }
                    ]
                },
                {
                    score: 2,
                    question: 'هل تم نشر سياسة الاحتفاظ بالوثائق وإتلافها على موقع الجمعية الإلكتروني؟',
                    evaluation: 'مكتبي',
                    check: 'محتوى سياسة الاحتفاظ بالوثائق وإتلافها المنشورة على موقع الجمعية الإلكتروني',
                    availableAnswers: [
                        { answer: "تم نشر السياسة بشكل متكامل", score: 1 },
                        { score: 0, answer: "لم يتم نشر السياسة" }
                    ]
                },
            ],
            score: 14
        }

        ],
        score: 25
    },
    {
        practices: [
            {
                questions: [
                    {
                        score: 2,
                        question: 'هل تم الإفصاح عن أسماء أعضاء الجمعية العمومية في الموقع الإلكتروني؟',
                        evaluation: 'مكتبي',
                        check: 'موقع قائمة بأسماء أعضاء الجمعية العمومية المنشورة على الموقع الإلكتروني للجمعية',
                        availableAnswers: [
                            { answer: "لم يتم الإفصاح عن الأسماء على الموقع الإلكتروني", score: 0 },
                            { score: 1, answer: "تم الإفصاح بشكل متكامل" }
                        ]
                    },
                    {
                        score: 2,
                        question: 'هل تم الإفصاح عن محاضر اجتماعات الجمعية العمومية في موقع الجمعية الإلكتروني؟',
                        evaluation: 'مكتبي',
                        check: 'موقع محاضر اجتماعات الجمعية العمومية ومحاضر فرز الأصوات المنشورة على الموقع الإلكتروني للجمعية',
                        availableAnswers: [
                            { answer: "لم يتم الإفصاح عن محاضر اجتماعات الجمعية العمومية ولا عن محاضر فرز الأصوات على الموقع الإلكتروني", score: 0 },
                            { answer: "تم الإفصاح بشكل جزئي", score: 0.5 },
                            { answer: "تم الإفصاح بشكل متكامل", score: 1 },
                        ]
                    },
                ],
                score: 4
            },
            {
                questions: [
                    {
                        score: 2,
                        question: 'هل تم الإفصاح عن أسماء أعضاء مجلس الإدارة ومدة دورة المجلس والمدة المتاحة للأعضاء في الموقع الإلكتروني؟',
                        evaluation: 'مكتبي',
                        check: 'موقع قائمة أسماء أعضاء مجلس الإدارة ومدة دورة المجلس والمدة المتاحة للأعضاء المنشورة على الموقع الإلكتروني للجمعية',
                        availableAnswers: [
                            { answer: "تم الإفصاح بشكل متكامل.", score: 1 },
                            { score: 0.5, answer: "تم الإفصاح بشكل جزئي" },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" }
                        ]
                    },
                    {
                        score: 1,
                        question: 'هل تم الإفصاح عن وجود علاقة عائلية أو تجارية بين أحد من أعضاء مجلس الإدارة أو المديرين أو الموظفين القياديين مع عضو مجلس إدارة أو مدير أو موظف قيادي آخر في الجمعية؟',
                        evaluation: 'مكتبي',
                        check: 'محتوى قائمة العلاقات العائلية والتجارية بين أعضاء المجلس والقياديين التنفيذيين والموظفين في الجمعية في نموذج الافصاح',
                        availableAnswers: [
                            { answer: "تم الإفصاح بشكل متكامل.", score: 1 },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" }
                        ]
                    },
                    {
                        score: 1,
                        question: 'هل تم الإفصاح عن تعاقدات الجمعية مع شركة لـعضو مجلس إدارة أو  أحد من أقاربه من الدرجة الأولى؟',
                        evaluation: 'مكتبي',
                        check: 'محتوى قائمة العلاقات العائلية والتجارية بين أعضاء المجلس والقياديين التنفيذيين والموظفين في الجمعية في انموذج الإفصاح',
                        availableAnswers: [
                            { answer: "تم الإفصاح بشكل متكامل.", score: 1 },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" }
                        ]
                    },
                    {
                        score: 1,
                        question: 'هل تم الإفصاح في الموقع الالكتروني أو نموذج الإفصاح عن وجود علاقة تعاقدية أو تجارية من الدرجة الأولى الى الدرجة الرابعة',
                        evaluation: 'مكتبي',
                        check: 'محتوى قائمة العلاقات العائلية والتجارية بين أعضاء المجلس والقياديين التنفيذيين والموظفين في الجمعية في نموذج الإفصاح',
                        availableAnswers: [
                            { answer: "تم الإفصاح بشكل متكامل.", score: 1 },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" }
                        ]
                    },
                    {
                        score: 0,
                        question: 'هل توجد لجان دائمة في الجمعية؟',
                        evaluation: 'مكتبي',
                        check: ['وجود قائمة أسماء اللجان الدائمة.', 'محتوى إجابات العاملين'],
                        availableAnswers: [
                            { answer: "توجد لجان دائمة", score: 0 },
                            { score: 0, answer: "لا توجد لجان دائمة" }
                        ]
                    },
                    {
                        score: 2,
                        question: 'هل تم الإفصاح عن أسماء اللجان الدائمة واختصاصاتها مع أسماء أعضائها؟',
                        evaluation: 'مكتبي',
                        check: 'موقع قائمة أسماء اللجان الدائمة المنشورة على الموقع الإلكتروني للجمعية',
                        availableAnswers: [
                            { answer: "تم الإفصاح بشكل متكامل.", score: 1 },
                            { score: 0.5, answer: "تم الإفصاح بشكل جزئي" },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" }
                        ]
                    },
                ],
                score: 7
            },
            {
                questions: [
                    {
                        score: 1,
                        question: ['هل يوجد لدى الجمعية سجل تجاري ساري المفعول؟', 'هل تم الإفصاح عن السجل التجاري والعاملين في نموذج الافصاح؟'],
                        evaluation: 'مكتبي + ميداني',
                        check: 'وجود الموقع الإلكتروني الرسمي للجمعية',
                        availableAnswers: [
                            { answer: "لا توجد لجان دائمة", score: 1 },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" },
                            { score: 0.5, answer: "تم الإفصاح بشكل جزئي" },
                            { score: 1, answer: "لم يتم الإفصاح بشكل منكامل" }
                        ]
                    },
                    {
                        score: 2,
                        question: 'هل تم الإفصاح عن اسم المدير التنفيذي وبيانات التواصل معه؟',
                        evaluation: 'مكتبي + ميداني',
                        check: 'موقع اسم المدير التنفيذي وبيانات التواصل معه المنشور على الموقع الإلكتروني للجمعية',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" },
                            { score: 0.5, answer: "تم الإفصاح بشكل جزئي" },
                            { score: 1, answer: "لم يتم الإفصاح بشكل منكامل" }
                        ]
                    },
                    {
                        score: 2,
                        question: ['هل توجد فروع أو مكاتب لدى الجمعية؟', 'هل تم الإفصاح عن أسماء مديري الفروع والمكاتب وبيانات التواصل معهم؟'],
                        evaluation: 'مكتبي',
                        check: ['وجود قائمة بالفروع والمكاتب التابعة للجمعية', 'موقع أسماء مديري الفروع والمكاتب وبيانات التواصل معهم المنشورة على الموقع الإلكتروني للجمعية'],
                        availableAnswers: [
                            { answer: "لا توجد فروع أو مكاتب لدى الجمعية", score: 1 },
                            { score: 0, answer: "لم يتم الإفصاح عن ذلك" },
                            { score: 0.5, answer: "تم الإفصاح بشكل جزئي" },
                            { score: 1, answer: "لم يتم الإفصاح بشكل منكامل" }
                        ]
                    },
                ],
                score: 5
            }
        ],
        score: 16
    },
    {
        practices: [
            {
                questions: [
                    {
                        score: 5,
                        question: ['هل لدى الجمعية نافذة لاستقبال الاستفسارات والمقترحات والشكاوى موضحة في موقعها الإلكتروني؟', 'هل تتفاعل الجمعية مع الاستفسارات والمقترحات والشكاوى؟'],
                        evaluation: 'مكتبي',
                        check: ['موقع نافذة استقبال الاستفسارات والمقترحات والشكاوى في الموقع الإلكتروني', 'محتوى سجل الاستفسارات والمقترحات والشكاوى التي تم أو يتم معالجتها'],
                        availableAnswers: [
                            { answer: "لا يوجد", score: 0 },
                            { answer: "لم يتم التفاعل معها", score: 0 },
                            { answer: "يتم التفاعل معها", score: 1 },
                        ]
                    }
                ],
                score: 5
            },
            {
                questions: [
                    {
                        score: 2,
                        question: "هل تم إجراء تقييمات لقياس رضا أصحاب العلاقة خلال السنة المالية الحالية أو السابقة؟",
                        evaluation: 'مكتبي + ميداني',
                        check: 'وجود الاستبانات التي قامت بها الجمعية',
                        availableAnswers: [
                            { score: 0, answer: "لا توجد استبانات" },
                            { score: 1, answer: "توجد استبانات" }
                        ]
                    },
                    {
                        score: 1.5,
                        question: "هل نسبة الاستجابة على قياس الرضا تمثل 50% من عدد مستفيدي الجمعية",
                        evaluation: 'ميداني',
                        check: ' محتوى الاستبانات ',
                        availableAnswers: [
                            { score: 0, answer: "لا تمثل الاستجابة 50" },
                            { score: 1, answer: "50%" }
                        ]
                    },
                    {
                        score: 2,
                        question: [
                            "هل تم تحليل الاستجابات التي تم جمعها من قياس الرضا",
                            "هل تم عرض النتائج على مجلس الإدارة؟",
                            "هل تم اتخاذ القرارات والتوصيات من مجلس الإدارة على نتائج التقييم؟"
                        ],
                        evaluation: 'ميداني',
                        check: 'وجود الموقع الإلكتروني الرسمي للجمعية',
                        availableAnswers: [
                            { answer: "لم يتم التحليل", score: 0 },
                            { answer: "لم يتم العرض", score: 0 },
                            { score: 0, answer: "لم يتم اتخاذ قرارات وتوصيات على نتائج التقييم." },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "يتم بشكل منكامل" }
                        ]
                    },
                    {
                        score: 1,
                        question: "هل تم نشر التغذية الراجعة من عمليات التقييم على منصات الجمعية الإلكترونية المناسبة؟",
                        evaluation: 'ميداني',
                        check: 'التغذية الراجعة المنشورة على منصات الجمعية الإلكترونية المناسبة.',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم النشر" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    },
                    {
                        score: 0.5,
                        question: "هل تم ابلاغ المعنين بنتجية التحسينات استنادا الى ملاحظاتهم؟",
                        evaluation: 'ميداني',
                        check: 'محتوى التواصل مع المعنين',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم التواصل" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    },
                    {
                        score: 1,
                        question: "هل يوجد تقرير يلخص تقييم المستفيدين النهائيين للخدمات التي تقدمها الجمعية؟",
                        evaluation: 'ميداني',
                        check: 'تقرير تقييم المستفيدين النهائيين لخدمات الجمعية ',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم النشر" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    },
                ],
                score: 8
            }
        ],
        score: 13
    },
    {
        practices: [
            {
                questions: [
                    {
                        score: 7,
                        question: "هل تنشر الجمعية تقارير برامجها وأنشطتها في منصاتها الإلكترونية أو موقعها الإلكتروني؟",
                        evaluation: 'مكتبي',
                        check: 'موقع أهداف الجمعية الاستراتيجية والتشغيلية المنشورة في الموقع الإلكتروني',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم النشر" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    }
                ],
                score: 7
            },
            {
                questions: [
                    {
                        score: 4,
                        question: "هل تنشر الجمعية قرارات الاستثمار أو التملك الصادرة من الجمعية العمومية أو مجلس الإدارة في منصاتها الإلكترونية أو موقعها الإلكتروني؟",
                        evaluation: 'مكتبي',
                        check: 'محتوى تقارير برامج وأنشطة الجمعية المنشورة في منصاتها الإلكترونية أو موقعها الإلكتروني',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم التواصل" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    },
                    {
                        score: 2,
                        question: "هل تنشر الجمعية قرارات الاستثمار أو التملك الصادرة من الجمعية العمومية أو مجلس الإدارة في منصاتها الإلكترونية أو موقعها الإلكتروني؟",
                        evaluation: 'ميداني',
                        check: 'محتوى تقارير برامج وأنشطة الجمعية المنشورة في منصاتها الإلكترونية أو موقعها الإلكتروني',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم التواصل" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    }
                ],
                score: 6
            },
            {
                questions: [
                    {
                        score: 7,
                        question: "هل تم نشر الإحصائيات الدقيقة المتعلقة بالمساعدات النقدية والعينية وأعداد وفئات المستفيدين منها في مواقع التواصل الاجتماعي للجمعية أو موقعها الإلكتروني؟",
                        evaluation: 'مكتبي',
                        check: 'محتوى الإحصائيات الدقيقة المتعلقة بالمساعدات النقدية والعينية وأعداد وفئات المستفيدين منها المنشورة في مواقع التواصل الاجتماعي للجمعية أو موقعها الإلكتروني',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم النشر" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    }
                ],
                score: 7
            },
            {
                questions: [
                    {
                        score: 2,
                        question: "هل تم إبلاغ المستهدفين من الأنظمة واللوائح والسياسات عند نشرها أو تحديثها؟",
                        evaluation: 'مكتبي',
                        check: 'تقارير التواصل مع المستهدفين لإبلاغهم عند نشر أو تحديث الأنظمة أو اللوائح أو السياسات',
                        availableAnswers: [
                            { score: 0, answer: "لم يتم النشر" },
                            { score: 0.5, answer: "تم بشكل جزئي" },
                            { score: 1, answer: "تم بشكل منكامل" }
                        ]
                    }
                ],
                score: 2
            },

        ],
        score: 22
    },
    {
        practices: [{
            questions: [
                {
                    score: 1,
                    question: "هل تم نشر التقرير المالي السنوي المدقّق والمعتمد للجمعية على موقع الجمعية الإلكتروني؟",
                    evaluation: 'مكتبي',
                    check: 'موقع التقرير المالي السنوي المدقّق للجمعية المنشور في الموقع الإلكتروني للجمعية',
                    availableAnswers: [
                        { score: 0, answer: "لم يتم النشر" },
                        { score: 0.5, answer: "تم بشكل جزئي" },
                        { score: 1, answer: "تم بشكل منكامل" }
                    ]
                }
            ],
            score: 13
        }],
        score: 13
    },
    {
        practices: [{
            questions: [
                {
                    score: 4,
                    question: "هل تم توفير البيانات المطلوبة في نموذج الافصاح في الوقت المحدّد؟",
                    evaluation: 'مكتبي',
                    check: 'محتوى نموذج الافصاح المحدّث للجمعية',
                    availableAnswers: [
                        { score: 0, answer: "لم يتم ذلك" },
                        { score: 0.5, answer: "تم توفيره بشكل جزئي" },
                        { score: 1, answer: "تم توفيره بشكل منكامل" }
                    ]
                },
                {
                    score: 3,
                    question: "هل تم اعتماد النموذج من رئيس مجلس الإدارة؟",
                    evaluation: 'مكتبي',
                    check: 'محتوى نموذج الافصاح المحدّث للجمعية بعد اعتماده من رئيس مجلس الإدارة',
                    availableAnswers: [
                        { score: 0, answer: "لم يتم الاعتماد من رئيس مجلس الإدارة" },
                        { score: 1, answer: "تم الاعتماد من رئيس مجلس الإدارة" }
                    ]
                },
                {
                    score: 4,
                    question: "هل تتطابق بيانات الجمعية المعتمدة في نموذج الإفصاح مع واقع الجمعية؟",
                    evaluation: 'ميداني',
                    check: [
                        "حتوى بيانات الجمعية في نموذج الإفصاح",
                        "محتوى الشواهد الميدانية وتناسقها"
                    ],
                    availableAnswers: [
                        { score: 0, answer: "لا تتطابق" },
                        { score: 1, answer: "تتطابق بشكل جزئي" },
                        { score: 1, answer: "تتطابق بشكل منكامل" }
                    ]
                }
            ],
            score: 11
        }],
        score: 11
    }
]
