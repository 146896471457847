import React, { useState } from "react";
import styled from "styled-components";
import Progress from "./Progress";
import Measurements from "./Measurements";
import { clarity } from '../data/clarityInd';

const Home = () => {
  const [measure, setMeasure] = useState(0)
  const [scores, setScores] = useState({});
  const [answers, setAnswers] = useState({});
  const [finalScore, setFinalScore] = useState(0);

  const handleNext = () => {
    setMeasure((prev) => prev + 1)
  }

  const handlePrev = () => {
    setMeasure((prev) => prev !== 0 ? prev - 1 : prev)
  }

  const calcFinalScore = () => {
    let finalScore = 0
    Object.values(scores).forEach((score) => {
      if (score) finalScore += +score
    })
    setFinalScore(finalScore)
  }
  return (
    <>
      <Progress steps={clarity} currentStep={measure} />
      <Measurements answers={answers} setAnswers={setAnswers} practices={clarity[measure].practices} measure={measure} scores={scores} setScores={setScores} setMeasure={setMeasure} max={clarity.length} />


      <ResultContainer>
        {finalScore && measure === clarity.length - 1 ? <FinalScoreTitle>
          {finalScore || 0}/100 الدرجة
        </FinalScoreTitle> : null}
        {clarity.length === measure + 1 ? <Button onClick={calcFinalScore}>
          احسب
        </Button> :
          <Button onClick={handleNext}>
            <svg  fill="white" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M32 15H3.41l8.29-8.29-1.41-1.42-10 10a1 1 0 0 0 0 1.41l10 10 1.41-1.41L3.41 17H32z" data-name="4-Arrow Left"/></svg>
            التالي
          </Button>}
        <Button prev onClick={handlePrev}>
         السابق
         <svg fill="white" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m31.71 15.29-10-10-1.42 1.42 8.3 8.29H0v2h28.59l-8.29 8.29 1.41 1.41 10-10a1 1 0 0 0 0-1.41z" data-name="3-Arrow Right"/></svg>
        </Button>
        
      </ResultContainer >

    </>
  )
}

const Button = styled.button`
  padding: 0.8rem;
  background-color:${props => (props.prev ? '#3a185b96' : '#3a185b')};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    padding: 5px 15px;
  }
  &:hover {
    background-color: #1e0b31;
  }
  @media (max-width: 768px) {
    width: 40%;
  }
`;

const FinalScoreTitle = styled.h2`
  margin: 0.5rem 0.5rem;
  font-size: 1.5rem;
  text-align:center;
  @media (max-width: 768px) {
    order:2;
  }
`;

const ResultContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 85%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width:95%;
    flex-wrap: wrap;
  }
`;

export default Home