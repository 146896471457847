import React, { useRef, useState } from 'react';
import videoUrl from "../data/New.mp4";
import log from '../data/logo.svg'
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const VideoContainer = styled.div`
    margin:2rem auto -2.5rem auto;
    width:100%;
    height:100%;
    @media (min-width: 768px) {
        top:0;
    }
`

const VideoPlayer = () => {
    const [isVisible, setIsVisible] = useState(true)
    const playerRef = useRef(null);
    const wrapperRef = useRef(null)


    const handlePlay = () => {
        if (wrapperRef.current) {
            // Trigger fullscreen when the video starts playing
            if (wrapperRef.current.requestFullscreen) {
                wrapperRef.current.requestFullscreen();
            } else if (wrapperRef.current.mozRequestFullScreen) {
                // For Firefox
                wrapperRef.current.mozRequestFullScreen();
            } else if (wrapperRef.current.webkitRequestFullscreen) {
                // For Chrome, Safari, and Opera
                wrapperRef.current.webkitRequestFullscreen();
            } else if (wrapperRef.current.msRequestFullscreen) {
                // For IE/Edge
                wrapperRef.current.msRequestFullscreen();
            }
        }
    };

    const handleExitFullscreen = () => {
        setTimeout(() => {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
            setIsVisible(false)
        }, 500)

    };

    if (!isVisible) return null;
    return (
        <VideoContainer ref={wrapperRef}>
            <ReactPlayer
                ref={playerRef}
                playing
                controls
                light={<img src={log} alt='Shai' />}
                onStart={handlePlay}
                url={videoUrl}
                width="100%"
                height="100%"
                onEnded={handleExitFullscreen}
            />
        </VideoContainer>
    )
};

export default VideoPlayer;
