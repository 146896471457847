import React, { useState } from 'react';
import styled from 'styled-components';
import LoadingComponent from './Loading'
import logo from '../data/sawaed-logo.png';
import logoBot from '../data/bot-logo.svg';
import axios from 'axios';

const LogoB = styled.img`
  cursor: pointer;
  bottom:1rem;
  height:3rem;
  left: 1rem;
  position: fixed;
  width:3rem;
  @media (min-width: 768px) {
   bottom:5rem;
   font-size: 16px;
   height:6rem;
   left: 5rem;
   width:6rem;
  }
`;

const ChatContainer = styled.div`
  display: flex;
  position: fixed;
  bottom:0;
  left:0;
  flex-direction: column;
  width: 95%;
  height: 65%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align:right;
  @media (min-width: 768px) {
   width: 650px;
   height: 650px;
  }
`;


// Messages display area
const MessagesArea = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
`;

// Message input container
const InputContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
`;

// Input field
const InputField = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  outline: none;
  text-align:right;
  min-width: 65%;
`;

// Send button
const SendButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

const Message = styled.div`
  background-color: ${props => (props.isUser ? '#4CAF50' : '#f1f1f1')};
  color: ${props => (props.isUser ? 'white' : 'black')};
  margin-left: ${props => (props.isUser ? "29%" : "0")};
  padding: 10px;
  border-radsius: 10px;
  margin-bottom: 10px;
  align-self: ${props => (props.isUser ? 'flex-end' : 'flex-start')};
  max-width: 85%;
`;

const CloseButton = styled.div`
  border: none;
  font-size: 3rem;
  color: #888;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;

const Header = styled.div`
  background: #e5e4e4;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:0.8rem;
`;

const Logo = styled.img`
  width: 100px;
  @media (min-width: 768px) {
   width: 200px;
  }
`;

const ChatInput = () => {
  const [message, setMessage] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsopen] = useState(false)

  const handleOpening = () => {
    setIsopen(prev => !prev)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default action (like form submission)
      sendMessage(); // Calls the send function
    }
  };

  const sendMessage = async () => {
    if (!message || loading) return;

    const newMessage = { role: 'user', content: message };
    setChatHistory([...chatHistory, newMessage]);
    setMessage('');
    setLoading(true);

    try {
      const response = await axios.post('/chat', { content: message });

      const replyMessage = {
        role: 'assistant',
        content: response.data.reply,
      };

      setChatHistory([...chatHistory, newMessage, replyMessage]);
    } catch (error) {
      console.error('Error communicating with ChatGPT API', error);
      setChatHistory([...chatHistory, { role: 'assistant', content: 'Error' }]);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isOpen ?
        <ChatContainer>
          <Header>
            <CloseButton onClick={handleOpening}>×</CloseButton>
            <Logo src={logo} alt="Sawaed" />
          </Header>
          <MessagesArea>
            {chatHistory.map((msg, index) => (
              <Message key={index} isUser={msg.role === 'user'}>
                {msg.content}
              </Message>
            ))}
            {loading && <LoadingComponent size={60}/>}
          </MessagesArea>
          <InputContainer>
            <InputField
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="اكتب سؤالك"
              onKeyDown={handleKeyDown}
            />
            <SendButton onClick={sendMessage}>{loading ? <LoadingComponent size={10}/> : 'ارسل'} </SendButton>
          </InputContainer>
        </ChatContainer>
        : <LogoB onClick={handleOpening} src={logoBot} alt="Bot" />
      }
    </>
  );
};

export default ChatInput;