import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: ${props => props.size/7}px solid #f3f3f3; 
  border-top: ${props => (props.size/7)}px solid #3498db; 
  border-radius: 50%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  animation: ${spin} 1s linear infinite;
  margin: ${props => props.size/3}px auto
`;

const Loading = ({ size }) => {
    return <Spinner size={size} />

};

export default Loading;