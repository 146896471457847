import React from 'react';
import styled from 'styled-components';
import logo from '../data/sawaed-logo.png';

const StyledHeader = styled.header`
  align-items: center;
  background-color: #fff;
  color: #3c126a;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px 15px;
  position: sticky;
  top: 0;
  z-index: 10;
  flex-wrap: wrap;
  @media (min-width: 768px) {
   flex-wrap: nowrap;
  }
`;

// Styled component for the logo
const Logo = styled.img`
  width: 100px;
  @media (min-width: 768px) {
   width: 220px;
  }
`;

const EmptyDiv = styled.div`
  width: 100px;
  @media (min-width: 768px) {
   width: 220px;
  }
`;

// Styled component for the title
const Title = styled.h1`
  font-size: 1rem;
  margin: 0;
  text-align: center;
  order:1;
  width:100%;
  @media (min-width: 768px) {
   font-size: 1.8rem;
   order:0;
   width:fit-content
  }
`;

// Styled component for the sign-out button
const SignOutButton = styled.div`
    cursor: pointer;
    width: 50px;
    img {
        width:40px;
    }
    background-color: transparent;
    @media (min-width: 768px) {
        width: 220px;
        img {
            width: 60px;
        }
    }
    
`;

const Header = ({ isAuthUser, setIsAuthUser }) => {
    return (
        <StyledHeader>
            <Logo src={logo} alt="Sawaed" />
            <Title>نموذج تقييم معيار الشفافية والإفصاح</Title>
            {isAuthUser ? <SignOutButton onClick={() => setIsAuthUser(false)}>
                تسجيل الخروج
            </SignOutButton> : <EmptyDiv />}
        </StyledHeader>
    );
};

export default Header;