import React, { useState } from "react";
import styled from "styled-components";
import logo from '../logo.svg';

const users = [
  { "email": "admin@shai.com", "password": "123456" }
]

const Auth = ({ setIsAuthUser }) => {
  const [isLogin, setIsLogin] = useState(true);

  const toggleAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('')

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormValues({ ...formValues, [name]: value });
    setErrorMessage('')
  };

  const handleSubmit = (isLogin) => {
    const {email, password, confirmPassword} = formValues
    if (!email || !password) {
      setErrorMessage('يرجى التأكد من البيانات المدخلة')
      return
    }
    const user = users.find(({ email: em }) => em === email)
    if (isLogin) {
      if (user && user?.password === password) {
        setIsAuthUser(true)
        return;
      }
      setErrorMessage('يرجى التأكد من البيانات المدخلة')
    }
    if (!user) {
      if (password !== confirmPassword) {
        setErrorMessage('يرجى التأكد من مطابقة كلمة المرور')
        return;
      }
      users.push({
        ...formValues
      })
      setIsAuthUser(true)
      return;
    }
    setErrorMessage('يرجى التأكد من البيانات المدخلة')

  }

  return (
    <Container>
      <AuthBox>
        <Image src={logo} alt="سواعد التقنية" />

        <Input name="email" type="email" placeholder="بريد الاكتروني" onChange={handleInputChange} required />
        <Input name="password" type="password" placeholder="كلمة المرور" onChange={handleInputChange} required />

        {!isLogin && <Input name="confirmPassword" type="password" placeholder="تأكيد كلمة المرور" onChange={handleInputChange} required />}

        {errorMessage && <Error>{errorMessage}</Error>}
        <Button onClick={() => handleSubmit(isLogin)}>{isLogin ? "تسجيل الدخول" : "تسجيل"}</Button>

        <SwitchText onClick={toggleAuthMode}>
          {isLogin
            ? "ليس لديك حساب ؟ قم بالتسجيل"
            : "لديك حساب قم بتسجيل الدخول"}
        </SwitchText>
      </AuthBox>
    </Container>
  );
};

export default Auth;

// Styled Components

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #f0f0f0;
`;

const AuthBox = styled.div`
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 320px;
  text-align: center;
`;

const Image = styled.img`
  margin-bottom: -1.5rem;
  margin-top: -1.5rem;
`;

const Input = styled.input`
  width: 85%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  text-align:right;
`;

const Error = styled.p`
  margin-top:0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: red;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const SwitchText = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #007bff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;