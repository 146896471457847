import { useState } from "react";
import Auth from "./components/Auth";
import Header from "./components/Header";
import Home from "./components/Home";
import ChatInput from "./components/ChatInput";
import VideoPlayer from "./components/VideoPlayer";

function App() {
  const [isAuthUser, setIsAuthUser] = useState(false)

  return (
    <div className="App">
      <Header isAuthUser={isAuthUser} setIsAuthUser={setIsAuthUser} />
      {isAuthUser ? <><Home /> <ChatInput /> </> : <><VideoPlayer /> <Auth setIsAuthUser={setIsAuthUser} /> </>}
    </div>
  );
}

export default App;
