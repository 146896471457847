import React from 'react';
import styled from 'styled-components';

// Container for the progress line and steps
const ProgressContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px 12%;
    position: relative;
    position: sticky;
    top: 92px;
    background: #fff;
    @media (max-width: 768px) {
      top: 122px;
      padding: 10px 12%;
    }
`;

const Line = styled.div`
  position: absolute;
  top: 50%;
  width: 75%;
  height: 4px;
  background-color: #e0e0e0;
  z-index: 1;
`;

// Filled progress line showing completion
const ProgressLine = styled.div`
  position: absolute;
  top: 50%;
  right: 12%;
  width: ${props => props.progress}%;
  height: 4px;
  background-color: #4CAF50;
  z-index: 2;
  transition: width 0.4s ease-in-out;
`;

// Step (the circles) along the progress line
const Step = styled.div`
  z-index: 3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => (props.completed ? '#4CAF50' : '#e0e0e0')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  transition: background-color 0.3s ease;
  @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      font-size: 10px;
  }
`;

// Check mark or step number
const CheckMark = styled.span`
  font-size: 18px;
  color: white;
  @media (max-width: 768px) {
      font-size: 10px;
  }
`;

const Progress = ({ steps=[1,2,3], currentStep = 1 }) => {
    const stepPercentage = (76 / (steps.length - 1)) * currentStep;
    

    return (
      <ProgressContainer>
        <Line />
        <ProgressLine progress={stepPercentage} />
        {steps.map((step, index) => (
          <Step key={index} completed={index <= currentStep}>
            <CheckMark>{index <= currentStep ? '✓' : index + 1}</CheckMark>
          </Step>
        ))}
      </ProgressContainer>
    );
  };

export default Progress;

