import React from "react";
import styled from "styled-components";

const Measurements = ({ practices, measure, scores, setScores, answers, setAnswers }) => {

  const handleChange = (e, key) => {
    const { value } = e.target;
    setScores({ ...scores, [key]: value })
    setAnswers({ ...scores, [key]: value })
  }



  return (
    <Container>
      {practices?.map(({ questions }, index2) => (
        <>
          <MeasureTitle>{` الممارسة رقم ${index2 += 1}`}</MeasureTitle>
          {questions?.map((ques, index3) => (
            <QuestionContainer key={measure + index3}>
              <QuestionTitle>
                <p>{scores[`${measure}${index2}${index3}`] || 0}/{ques.score} :الدرجة</p>
                <p>{`التقييم: ${ques.evaluation} `}</p>
                <h3>{typeof ques.question === 'string' ? ques.question : ques.question.map((q) => (
                  <>
                    {q}<br />
                  </>
                )
                )}</h3>
              </QuestionTitle>

              <h4>آلية التحقق</h4>
              <ListItem>
                {typeof ques?.check === 'string' ?
                  <li>
                    {ques.check}
                  </li>
                  :
                  ques?.check.map((item) => (
                    <li key={item}>
                      {item}
                    </li>
                  ))
                }
              </ListItem>

              <Select value={answers[`${measure}${index2}${index3}`]} onChange={(e) => handleChange(e, `${measure}${index2}${index3}`)}>
                <option />
                {ques.availableAnswers.map(
                  ({ answer, score }) => (
                    <option value={score * ques.score}>{answer}</option>
                  )
                )}
              </Select>
            </QuestionContainer>
          ))}
        </>
      ))}
    </Container>

  );
};
// 
export default Measurements;

const Container = styled.div`
  text-align:right;
  background-color: #f0f0f0;
`;

const MeasureTitle = styled.h2`
  margin: 0.5rem 2.5rem;
  font-size: 1.5rem;
  color: #000;
`;

const QuestionContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0.9rem auto;
  padding: 1rem;
  width: 85%;
  h4 {
    margin: 0.5rem 0;
  }
`;

const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column-reverse;
  h3 {
    margin: 0.5rem;
  }
  p {
    margin: 0.25rem;
  }
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 5fr;
  }
`;

const ListItem = styled.ul`
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 0;
  li {
    direction: rtl;
  }
`

const Select = styled.select`
  padding: 0.8rem;
  margin: 0.2rem auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  width:90%;
  text-align:right;
  @media (min-width: 768px) {
    width:fit-content;
    margin: 0.3rem 0;
  }
`;
